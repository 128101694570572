/*
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-10-12 15:38:29
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-11-12 17:15:31
 */
export default {
    label: '',
    type: 'isOpen',
    property: '',
    value: false,
    options: {
    }
}
