


/*export default {
    label: '数组',
    type: 'tagSource',
    property: '',
    value: [],
    options: {
        "url": "", //数据源的URL地址
        "method": "", //数据源的请求方法
        "name": "", //数据源名称
        "remark": "", //数据源备注信息
        "serviceName": "" //数据源所属服务
    }
}*/



export default {
    label: '标签数组',
    labelSwitch: true,
    type: 'tagSourceNew',
    property: '',
    value: ''
}