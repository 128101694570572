export default {
  label: "热词列表",
  type: "hotWordlist",
  property: "",
  value: [],
  child: [],
  options: {
    note: "",
    limit: 10,
  },
};
