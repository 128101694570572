/*
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-10-12 15:38:29
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-11-12 17:16:21
 */
export default {
  label: '数组',
  type: 'arrayImg',
  property: '',
  value: [],
  child: [],
  options: {
    note: '',
    limit: 10,
    buttonName: '新增数据',
    hideAdd: false
  }
}
